.custom-nav{
    height: 75px;
    z-index: 100;
    display: flex;
    padding: 0 20px 0 20px;
}

.custom-button-border{
    border: 1px solid #0063E7
}

.custom-button-border:hover{
    border: 1px solid #0063E7 !important
}

.custom-nav-hieght-75{
    height: 75px;
}

.logo-m{
    margin-right: 36px;
}

.custom-font-f{
    font-family: 'Source Sans Pro', sans-serif;
}

.custom-without-margin{
    margin: 0 !important;
}

.custom-columns-without-margin{
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.custo-text-color{
    color: #FFA000;
}

.custom-m-top{
    margin-top: 80px;
}

.custo-margin-arrow{
    margin-right: 10px;
}

.custom-content-margin{
    margin-top: 25px;
}

.custom-txt-margin{
    margin-bottom: 10px;
}

.custom-margin-bottom-12{
    margin-bottom: 12px;
}

.custo-footer-margin{
    margin-top: 280px;
    padding-left: 0;
    padding-right: 0;
}

.custom-auto-margin{
    margin-left: auto;
    margin-right: auto;
}

.custom-reverse-img{
    transform: scaleX(-1);
}

.custom-footer-unios-logo{
    height: 64px;
    align-items: flex-end;
    display: flex;
    margin-bottom: 70px !important;
    margin-top: 10%;
}

.custom-footer-icons{
    margin-right: 10px;
    margin-left: 15px;
}

.custo-foter-margin-left{
    margin-left: 10px;
}

.custom-foter-margin-right{
    margin-right: 10px;
}

.custom-foter-margin-right-45{
    margin-right: 45px;
}

.custom-footer-padding-top-134{
    padding-top: 134px;
}

.custom-vendors-min-height{
    min-height: 560px;
}

.custom-vendors-max-width{
    max-width: 400px;
}

.custom-vendors-content{
    margin-left: auto;
    margin-right: auto;
}

.custom-vendors-content-max-width{
    @extend .custom-vendors-content;
    max-width: 1200px;
}

.custom-position-relative{
    position: relative;
}

.custom-blue-arrow{
    position: absolute;
    left: -15px;
    top: 7px;
}

.custom-title{
    min-height: 80px;
    padding: 10px;
}

.custo-font-size-36{
    font-size: 36px !important;
}

.custom-max-width-500{
    @extend .custom-vendors-content;
    max-width: 500px;
}

.custom-is-820x560{
    max-width: 820px;
    max-height: 560px;
}

.custom-presentation-card-max-width{
    max-width: 270px;
}

.custom-news-date{
    color: #0096E9!important;
}

.custom-text-clipping{
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 700px;
}

.custom-news-hove:hover{
    cursor: pointer;
    background: #fff;
    box-shadow: 0px 4px 20px rgb(0 26 255 / 20%);
}

.custom-base-font-color{
    color: #1A1F36 !important;
}

.custom-blue-font-color{
    color: #0096E9;
}

.custom-line-h-16{
    line-height: 16px;
}

.custom-vendors-max-width-745{
    max-width: 745px;
}

.custom-img-height-8{
    height: 8px;
}

.custom-header-section-active::after{
    position: absolute;
    content: '';
    background: #0096E9;
    width: 100%;
    height: 3px;
    bottom: -17px;
    left: 0;
}

.custom-margin-bottom-16{
    margin-bottom: 16px !important;
}

.custom-margin-bottom-31{
    margin-bottom: 31px !important;
}

.custom-margin-bottom-55{
    margin-bottom: 55px !important;
}

.custom-m-width-137{
    width: 137px;
}

.custom-header-text-color{
    color: #333333;
}

.custom-flex-50{
    flex: 0 0 50%;
}

.custom-margin-right-70{
    margin-right: 62px;
}

.custom-mobile-menu{
    position: fixed;
    top: 75px;
    width: 100%;
    background: #fff;
    height: 800px;
    z-index: 1000;
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
}

.slide-in {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}

.slide-out {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
}
    
@keyframes slide-in {
    100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in {
    100% { -webkit-transform: translateX(0%); }
}
    
@keyframes slide-out {
    0% { transform: translateX(0%); }
    100% { transform: translateX(-100%); }
}

@-webkit-keyframes slide-out {
    0% { -webkit-transform: translateX(0%); }
    100% { -webkit-transform: translateX(-100%); }
}

.custom-is-width-425{
    width: 425px;
}

.custom-margin-top-70{
    margin-top: 70px;
}

.custom-margin-bottom{
    margin-bottom: 165px !important;
}

.custom-margin-bottom-60{
    margin-bottom: 60px !important;
}

.custom-news-padding{
    padding: 51px 15px 58px 15px;
}

.custom-back-btn{
    background: #373868 !important;
    border: 1px solid #2a2b55 !important;
    color: #FFFFFF !important;
}

.custom-back-btn:hover{
    box-shadow: 0px 2px 6px rgba(55, 56, 104, 0.5) !important;
}

.custom-devs-container{
    max-width: 790px;
    display: flex;
}

.custom-break-space{
    white-space: pre-line;
}

.custom-news-fulltext{
   white-space: pre-wrap;      /* CSS3 */   
   white-space: -moz-pre-wrap; /* Firefox */    
   white-space: -pre-wrap;     /* Opera <7 */   
   white-space: -o-pre-wrap;   /* Opera 7 */    
   word-wrap: break-word;
}

.custom-font-size-30{
    font-size: 30px !important;
}

.custom-code-area{
    background: #F3F5FF;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;
    display: block;
}

.custom-margin-top-91{
    margin-top: 91px;
}

.custom-margin-bottom-40{
    margin-bottom: 40px !important;
}

.custom-margin-t-b{
    margin-top: 40px;
    margin-bottom: 50px;
}

.custom-underline{
    text-decoration: underline;
}

.custom-opacity{
    opacity: 0.5;
}

.custom-header-logo{
    position: absolute !important;
    max-width: 100%;
    top: 50%;
    transform: translateY(-50%);
    right: 100%;
    width: max-content;
}

.custom-margin-left-auto{
    margin-left: auto;
}

.custom-without-border{
    border: unset !important;
}

.custom-flex-0-0-100{
    flex: 0 0 100%;
}

.custom-max-width-506{
    max-width: 506px;
}

.custom-max-width-img{
    max-width: 699px;
    width: 100%;
}

.custom-border-radius{
    border: 2px solid #1A1F36;
    border-radius: 10px;
    height: 270px;
}

.custom-media-content{
    margin-top: 7px;
    margin-bottom: 21px;
}

.custom-footer-logo-max-height{
    max-height: 50px;
}

.custom-flex-0-0-65{
    flex: 0 0 65%;
}

.custom-footer-iconsyi{
    height: 30px;
    width: 30px;
}

// responsive classes

@media(min-width: 320px){
    .custom-nav{
        width: 100%;
        position: fixed;
        top: 0;
        background-color: #FFFFFF;
   }

   .logo-m{
       display: flex;
   }

   .custom-without-padding{
        padding: 0;
    }

    .custom-footer-width{
        width: 85%;
        max-width: 610px;
    }

    .custom-max-width-responsive{
        width: 85%;
        max-width: 610px;
    }

    .custom-footer-man {
        position: absolute;
        bottom: 12px;
        right: 1px;
        margin-right: 30px;
        width: 154px;
    }

    .custom-bcg-img{
        background-position: right;
        background-repeat: no-repeat;
        height: 150px;
        background-size: auto 150px;
        margin-top: 0;
    }

    .custom-bcg-img-main-page{
        background-position: center;
        background-repeat: no-repeat;
        height: 249px;
        background-size: auto 249px;
    }

    .custom-responsive-margin{
        margin-top: 55%;
    }

    .custom-page-title{
        width: 308px;
        max-width: 350px;
        font-size: 19px;
        line-height: 25px;
    }

    .custom-main-page-title{
        font-size: 18px;
        line-height: 24px;
    }

    .custom-main-page-subtitle{
        font-size: 12px;
        line-height: 16px;
    }

    .custom-txt-with-arrow{
        font-size: 12px;
        line-height: 16px;
    }

    .custom-main-page-txt-width{
        max-width: unset;
    }

    .custom-mainpage-title-width{
        width: 65%;
    }

    .custom-main-page-images{
        width: 100%;
        max-width: 400px;
    }

    .page-content-h4 {
        font-size: 18px;
        line-height: 24px;
    }

    .page-content-h2{
        font-size: 24px !important;
        line-height: 32px !important;
    }

    .abc{
        margin-top: 150px;
    }

    .responsive-btn{
        min-width: 100%;
    }

    .custo-font-size-36{
        font-size: 24px !important;
    }

    .custom-title{
        font-weight: 900;
        font-size: 18px;
    }
 }

@media(min-width: 480px){
   .logo-m{
       display: flex;
   }

    .custom-footer-width{
        width: 90%;
        max-width: 610px;
    }

    .custom-max-width-responsive{
        width: 90%;
        max-width: 610px;
    }

    .custom-footer-man {
        position: absolute;
        bottom: 12px;
        right: 1px;
        margin-right: 30px;
        width: 199px;
    }

    .custom-bcg-img{
        background-position: right;
        background-repeat: no-repeat;
        height: 202px;
        background-size: auto 202px;
        margin-top: 0;
    }

    .custom-bcg-img-main-page{
        background-position: center;
        background-repeat: no-repeat;
        height: 423px;
        background-size: auto 423px;
    }

    .custom-page-title{
        width: 350px;
        max-width: 350px;
        font-size: 19px;
        line-height: 25px;
    }

    .custom-responsive-margin{
        margin-top: 55%;
    }

    .custom-main-page-title{
        font-size: 18px;
        line-height: 24px;
    }

    .custom-main-page-subtitle{
        font-size: 14px;
        line-height: 19px;
    }

    .custom-main-page-txt-width{
        max-width: unset;
    }

    .page-content-h4 {
        font-size: 18px;
        line-height: 24px;
    }

    .page-content-h2{
        font-size: 36px !important;
        line-height: 48px !important;
    }

    .responsive-btn{
        min-width: none;
    }

    .custo-font-size-36{
        font-size: 24px !important;
    }

    .custom-title{
        font-weight: 900;
        font-size: 18px;
    }
 }

@media(min-width: 768px){
   .logo-m{
       display: flex;
   }

    .custom-footer-width{
        max-width: 610px;
    }

    .custom-max-width-responsive{
        width: 100%;
        max-width: 610px;
    }

    .custom-footer-man {
        position: absolute;
        bottom: -19px;
        right: 1px;
        margin-right: 30px;
    }

    .custom-bcg-img{
        background-position: right;
        background-repeat: no-repeat;
        height: 232px;
        background-size: auto 232px;
        margin-top: 0;
    }

    .custom-bcg-img-main-page{
        background-position: center;
        background-repeat: no-repeat;
        height: 455px;
        background-size: auto 455px;
    }

    .custom-page-title{
        max-width: 350px;
        font-size: 24px;
        line-height: 32px;
    }

    .custom-page-title2{
        font-size: 36px;
        line-height: 48px;
    }

    .custom-responsive-margin{
        margin-top: 15%;
    }

    .custom-main-page-title{
        font-size: 24px;
        line-height: 32px;
    }

    .custom-main-page-subtitle{
        font-size: 16px;
        line-height: 21px;
    }

    .custom-txt-with-arrow{
        font-size: 12px;
        line-height: 16px;
    }

    .custom-main-page-txt-width{
        max-width: unset;
    }

    .page-content-h4 {
        font-size: 14px;
        line-height: 19px;
    }

    .page-content-h2{
        font-size: 24px !important;
        line-height: 32px !important;
    }

    .abc{
        margin-top: 0px;
    }

    .custo-font-size-36{
        font-size: 24px !important;
    }

    .custom-title{
        font-weight: 900;
        font-size: 18px;
    }
 }

@media(min-width: 1024px){
    .logo-m{
        display: none;
    }

    .custom-footer-width{
        max-width: 920px;
    }

    .custom-max-width-responsive{
        width: 920px;
        max-width: 920px;
    }

    .custom-footer-man {
        position: absolute;
        bottom: -19px;
        right: 1px;
        margin-right: 30px;
        width: 350px;
    }

    .custom-flex-50{
        flex: 0 0 56%;
    }

    .custom-bcg-img{
        background-position: right;
        background-repeat: no-repeat;
        height: 429px;
        background-size: auto 429px;
        margin-top: 0px;
    }

    .custom-bcg-img-main-page{
        background-position: center;
        background-repeat: no-repeat;
        height: 429px;
        background-size: auto 429px;
        margin-top: 75px;
    }

    .custom-page-title{
        max-width: 400px;
        font-size: 24px;
        line-height: 32px;
    }

    .custom-page-title2{
        font-size: 48px;
        line-height: 64px;
    }

    .custom-responsive-margin{
        margin-top: 15%;
    }

    .custom-main-page-title{
        font-size: 36px;
        line-height: 48px;
    }

    .custom-main-page-subtitle{
        font-size: 20px;
        line-height: 27px;
    }

    .custom-txt-with-arrow{
        font-size: 16px;
        line-height: 21px;
    }

    .custom-main-page-txt-width{
        max-width: 500px;
    }

    .page-content-h4 {
        font-size: 16px;
        line-height: 21px;
    }

    .page-content-h2{
        font-size: 36px !important;
        line-height: 48px !important;
    }

    .custom-title{
        font-weight: 900;
        font-size: 18px;
    }

    .custo-font-size-36{
        font-size: 36px !important;
    }
 }

 @media(min-width: 1440px){
    .custom-nav{
        width: 100%;
         max-width: 920px;
    }

    .logo-m{
        display: flex;
    }

    .custom-footer-man {
        position: absolute;
        bottom: -19px;
        right: 1px;
        margin-right: 30px;
    }


    .custom-footer-width{
        max-width: 1200px;
    }

    .custom-max-width-responsive{
        width: 100%;
        max-width: 1200px;
    }

    .custom-flex-50{
        flex: 0 0 50%;
    }

    .custom-bcg-img{
        background-position: right;
        background-repeat: no-repeat;
        height: 560px;
        background-size: auto 560px;
        margin-top: 75px;
    }

    .custom-bcg-img-main-page{
        background-position: center;
        background-repeat: no-repeat;
        height: 560px;
        background-size: auto 560px;
        margin-top: 75px;
    }

    .custom-page-title{
        width: 100%;
        max-width: 594px;
        font-size: 36px;
        line-height: 48px;
    }

    .custom-responsive-margin{
        margin-top: 0;
    }

    .custom-main-page-txt-width{
        max-width: unset;
    }

    .custom-mainpage-title-width{
        width: 100%;
    }

    .page-content-h4 {
        font-size: 18px;
        line-height: 24px;
    }

    .custom-title{
        font-weight: 900;
        font-size: 18px;
    }
 }

 @media(min-width: 1920px) {
    .custom-nav{
        width: 100%;
        max-width: 1200px;
   }

     .logo-m{
         display: flex;
     }

    .custom-footer-man{
        position: absolute;
        bottom: -19px;
        right: 1px;
        margin-right: 195px;
    }
 }

// Переназначение стилей Bulma

a.navbar-item:hover{
    color: #0096E9;
}

a.navbar-item:focus{
    color: #333333!important;
}

.is-grid{
    display: grid !important;
}

.navbar-burger:hover{
    background-color: #FFFFFF;
}

.navbar-burger:checked{
    background-color: #FFFFFF;
}
